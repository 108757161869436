<!--
 * @Author: mulingyuer
 * @Date: 2021-09-17 16:19:35
 * @LastEditTime: 2021-10-21 17:40:16
 * @LastEditors: mulingyuer
 * @Description: 日期范围选择器
 * @FilePath: \saas-admin-vue\src\modules\gov\components\bid-data\MyDatePicker.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="my-date-picker">
    <div
      class="my-date-picker-btn-group"
      :style="{
        display: isInline ? 'inline-block' : 'block',
      }"
    >
      <template v-for="item in btnArr">
        <el-button
          :key="item.type"
          class="btn"
          :class="{ active: activeBtnType === item.type }"
          type="text"
          :size="size"
          :disabled="disabled"
          @click="onBtnClick(item.type)"
        >
          {{ item.name }}
        </el-button>
      </template>
    </div>
    <el-date-picker
      v-model="timeArr"
      :type="type"
      :range-separator="attach"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :value-format="valueFormat"
      :disabled="disabled"
      :size="size"
      class="date-picker"
      @change="onDatePickerChange"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'handleValue',
  },
  props: {
    //绑定的值
    value: {
      type: Array,
      required: true,
    },
    //开始时间文字占位符
    startPlaceholder: {
      type: String,
      default: '开始日期',
    },
    //连接符
    attach: {
      type: String,
      default: '至',
    },
    //结束时间文字占位符
    endPlaceholder: {
      type: String,
      default: '结束日期',
    },
    //显示类型
    type: {
      type: String,
      default: 'daterange',
    },
    //返回值的格式
    valueFormat: {
      type: String,
      default: 'timestamp',
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    //是否允许文字和时间一行对齐
    isInline: {
      type: Boolean,
      default: true,
    },
    //大小
    size: {
      type: String,
      default: 'mini',
      transform: (val) => {
        return ['large', 'small', 'mini'].includes(val)
      },
    },
    //默认快捷选项
    defaultTimeType: {
      type: String,
      default: 'moon',
    },
  },
  data() {
    return {
      activeBtnType: this.defaultTimeType,
      btnArr: [
        { name: '昨天', type: 'yesterday', day: -1 },
        { name: '最近7天', type: 'week', day: 7 },
        { name: '最近30天', type: 'moon', day: 30 },
        { name: '最近一年', type: 'year', day: 365 },
      ],
    }
  },
  computed: {
    timeArr: {
      get() {
        let arr = []
        if (this.value.length) {
          this.value.forEach((n) => {
            if (n && n !== -1) {
              arr.push(n * 1000)
            } else {
              // 空数组才不会默认选择1972年
              // arr.push("");
            }
          })
        }
        return arr
      },
      set(val) {
        let arr = []
        if (val && val.length) {
          val.forEach((n) => {
            arr.push(n ? n / 1000 : -1)
          })
        }
        this.$emit('handleValue', arr)
      },
    },
  },
  methods: {
    //快捷按钮
    onBtnClick(type, isChange = true) {
      this.activeBtnType = type
      const findData = this.btnArr.find((item) => item.type === type)
      if (!findData) return console.error('未知的时间类型')

      const currentTime = new Date().setHours(0, 0, 0, 0) //当前凌晨时间戳
      let endTime = currentTime - 86400000 * Math.abs(findData.day)
      this.timeArr = [endTime, currentTime]
      //触发change事件
      if (isChange) this.$emit('change', this.timeArr)
    },
    //时间选择发生变化
    onDatePickerChange() {
      this.activeBtnType = ''
      //触发change事件
      this.$emit('change', this.timeArr)
    },
    //更新绑定数据-外部接口使用
    updateTime() {
      if (this.defaultTimeType) {
        this.activeBtnType = this.defaultTimeType
        this.onBtnClick(this.defaultTimeType, false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.my-date-picker {
  text-align: right;
  .my-date-picker-btn-group {
    // display: inline-block;
    margin: 2px 0;
    .btn {
      color: #999;
      &.active {
        color: $primary;
      }
      &:last-of-type {
        margin-right: 10px;
      }
    }
  }

  .date-picker {
    margin: 2px 0;
    width: 280px;
    ::v-deep.el-range-separator {
      width: auto;
    }
  }
}
</style>
