<!--
 * @Author: mulingyuer
 * @Date: 2021-10-21 10:35:00
 * @LastEditTime: 2021-10-21 11:09:08
 * @LastEditors: mulingyuer
 * @Description: 协会下拉选项
 * @FilePath: \saas-admin-vue\src\modules\gov\components\bid-data\AssociationSelect.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-select v-model="projectId" size="mini" style="width:100px" @change="onChange">
    <el-option v-for="op in associationArr" :key="op.project_id" :label="op.name" :value="op.project_id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "handleValue",
  },
  props: {
    //绑定的数据
    value: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    //选项数据
    associationArr({ $store }) {
      return $store.getters["bigData/associationList"];
    },
    //双向绑定
    projectId: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("handleValue", val);
      },
    },
  },
  methods: {
    //变化
    onChange() {
      this.$emit("change", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
