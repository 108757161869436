/*
 * @Author: mulingyuer
 * @Date: 2021-09-17 14:50:09
 * @LastEditTime: 2021-12-22 10:08:43
 * @LastEditors: aleaner
 * @Description: 柱状图表
 * @FilePath: \saas-admin-vue\src\modules\organization-admin\assets\echarts\bar-chart.js
 * 怎么可能会有bug！！！
 */
import Chart from './chart'
import merge from 'merge'
//图表
import * as echarts from 'echarts/core'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { isObject } from '@/base/utils/tool'

echarts.use([
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
])

export default class myBarChart extends Chart {
  //悬浮展示配置
  tooltip = {
    trigger: 'item',
    extTexts: [],
    formatter: (params, ticket, callback) => {
      let tipText = ''
      if (isObject(params.data)) {
        tipText = `${params.name.replace(/\//g, '-')}<br/>${
          params.data.mp_count || params.data.h5_count
        } / ${params.data.total}人 (占比：${params.value}%)`
      } else {
        tipText = `${params.name.replace(/\//g, '-')}：${params.value}`
      }

      return tipText
    },
  }
  //图例配置
  legend = {
    right: 0,
    top: 0,
    icon: 'rect',
    // formatter: (name) => {
    //   const findData = this.data.find((item) => item.name === name);
    //   if (!findData) return name;
    //   return `${name} |    ${findData.value}%`;
    // },
  }
  //布局配置
  grid = {
    left: '10px',
    right: '10px',
    bottom: '0',
    top: '10px',
    containLabel: true,
  }

  //x轴配置
  xAxis = {
    type: 'category',
    nameLocation: 'center',
    data: [],
    axisLabel: {
      interval: 0,
    },
  }
  //y轴配置
  yAxis = {
    type: 'value',
    nameLocation: 'end',
  }
  //图形配置
  series = {
    name: '',
    type: 'bar',
    barWidth: 30,
    data: [],
  }

  // y轴缩放配置
  dataZoom = {
    start: 0,
    end: 100,
  }

  dataZoomEnd = ''

  constructor(options = {}) {
    super(options)

    this.init(options)
  }

  //初始化
  init(options) {
    //校验+合并参数
    const validData = this.initValidatorAndMerge(options)

    //获取图表数据
    const { container, xData, data, resize } = validData
    if (xData.length > 6) {
      echarts.use(DataZoomComponent)
    }

    //初始化实例
    this.$myChart = echarts.init(container)
    //设置图表数据
    this.setOption(xData, data)

    if (validData.extraData && validData.extraData.totals) {
      // 设置tooltip额外显示数据
      this.tooltip.extTexts = validData.extraData.totals
    }

    //监听容器resize
    if (resize) this.addResize()
  }

  //初始化校验+合并参数
  initValidatorAndMerge(options) {
    const {
      container,
      color,
      tooltip,
      showTooltip = true,
      legend,
      showLegend = true,
      series,
      xAxis,
      yAxis,
      xData,
      data,
      grid,
      resize = true,
      extraData,
      dataZoomEnd,
    } = options

    //容器
    this.container = this.validateContainer(container)
    //xData
    this.validateXData(xData)
    //data
    this.validateData(data)

    //tooltip
    this.showTooltip = showTooltip
    if (showTooltip && tooltip)
      merge.recursive(this.tooltip, this.validateTooltip(tooltip))
    //legend
    this.showLegend = showLegend
    if (showLegend && legend)
      merge.recursive(this.legend, this.validateLegend(legend))
    //series
    if (series) merge.recursive(this.series, this.validateSeries(series))
    //xAxis
    if (xAxis) merge.recursive(this.xAxis, this.validateXAxis(xAxis))
    //yAxis
    if (yAxis) merge.recursive(this.yAxis, this.validateYAxis(yAxis))
    // grid
    if (grid) merge.recursive(this.grid, this.validateGrid(grid))
    //resize
    this.resize = this.validateResize(resize)
    //颜色
    if (color) this.color = this.validateColor(color)
    // 区域缩放百分百比
    if (dataZoomEnd) this.dataZoomEnd = dataZoomEnd

    //返回出去
    return {
      container,
      color,
      tooltip,
      showTooltip,
      legend,
      showLegend,
      series,
      xAxis,
      yAxis,
      xData,
      data,
      grid,
      resize,
      extraData,
    }
  }

  //设置数据
  setOption(xData, data) {
    this.xAxis.data = this.validateXData(xData) //挂载坐标数据
    this.data = this.validateData(data) //挂载，方便formatter查询

    let series = []
    let dataLength = xData.length

    if (isObject(this.data)) {
      let index = 0
      for (let key in this.data) {
        series.push(
          JSON.parse(
            JSON.stringify(
              merge.recursive(this.series, {
                data: this.data[key],
                name: this.legend.data[index],
              })
            )
          )
        )
        index++
      }
    } else {
      series = [merge.recursive(this.series, { data })]
    }

    if (dataLength > 6) {
      this.grid.bottom = '50px'
    }

    //配置对象
    const options = {
      color: this.color,
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      grid: this.grid,
      series,
    }
    console.log(this.dataZoomEnd, 'dataZoomEnd')

    switch (true) {
      case dataLength > 6 && dataLength <= 12:
        Object.assign(this.dataZoom, { end: 50 })
        Object.assign(options, { dataZoom: this.dataZoom })
        break
      case dataLength > 12 && dataLength <= 24:
        Object.assign(this.dataZoom, { end: 30 })
        Object.assign(options, { dataZoom: this.dataZoom })
        break
      case dataLength > 24:
        Object.assign(this.dataZoom, { end: 20 })
        Object.assign(options, { dataZoom: this.dataZoom })
        break
    }

    // 如果有传自定义参数
    if (this.dataZoomEnd) {
      Object.assign(this.dataZoom, { end: this.dataZoomEnd })
      Object.assign(options, { dataZoom: this.dataZoom })
    }

    console.log(options, 'options')
    //是否显示组件
    if (this.showLegend) options.legend = this.legend
    if (this.showTooltip) options.tooltip = this.tooltip

    //设置
    this.$myChart.setOption(options)
  }
}
