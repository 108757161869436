<!--
 * @Author: mulingyuer
 * @Date: 2021-09-17 16:02:13
 * @LastEditTime: 2021-10-21 17:54:54
 * @LastEditors: mulingyuer
 * @Description: 会员增长趋势
 * @FilePath: \saas-admin-vue\src\modules\gov\components\bid-data\MemberGrowth.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard title="用户增长趋势" :loading="loading">
    <template #headRight>
      <div class="head-box">
        <div class="select-box">
          <el-select
            v-model="form.user_type"
            placeholder="请选择"
            @change="getData"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <MyDatePicker
          v-model="form.time"
          ref="MyDatePicker"
          @change="changeTime"
        />
      </div>
    </template>
    <div class="member-growth">
      <div class="head"></div>
      <div class="content" ref="chartContent"></div>
    </div>
  </BDCard>
</template>
<script>
import BDCard from '@/modules/gov/layout/big-data/BDCard.vue'
import AssociationSelect from '@/modules/gov/components/bid-data/AssociationSelect.vue'
import MyDatePicker from '@/modules/gov/components/bid-data/MyDatePicker.vue'
import MyLineChart from '@/modules/gov/assets/echarts/line-chart.js'

import { getUserGrowth } from '@/modules/college/api/college-study-overview.js'
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        time: [-1, -1],
        user_type: 'user',
        // start_time: -1, //开始时间
        // end_time: -1, //结束时间
      },
      $chart: null, //图表实例
      userGrowthData: {}, //api获取的数据
      //图表数据
      chartData: [],
      //x轴坐标数据
      xData: [],
      // 会员类型
      typeOptions: [
        {
          label: '游客',
          value: 'user',
        },
        {
          label: '学员',
          value: 'study_user',
        },
      ],
    }
  },
  methods: {
    changeTime(e) {
      // console.log(this.form.time,'e')
      if (this.form.time.length) {
        this.getData()
      }
    },
    //获取会员增长趋势
    getData() {
      this.loading = true
      const postData = this.getFormData()
      getUserGrowth(postData)
        .then((res) => {
          const { data } = res
          this.userGrowthData = data.list

          this.transformData()
          this.initChart()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //转换数据格式
    transformData() {
      this.chartData = this.userGrowthData.map((item) => item.value)
      this.xData = this.userGrowthData.map((item) => item.title)
      // console.log(this.chartData, this.xData)
    },
    //获取提交的form数据
    getFormData() {
      const data = { ...this.form }
      const [start_time = '', end_time = ''] = data.time
      Object.assign(data, { start_time, end_time })
      Reflect.deleteProperty(data, 'time')
      return data
    },
    //初始化图表
    initChart() {
      console.log(
        this.getAxisLabel(this.form.time),
        'this.getAxisLabel(this.form.time)'
      )
      this.$chart = new MyLineChart({
        container: this.$refs.chartContent,
        data: this.chartData,
        xData: this.xData,
        grid: {
          top: 50, // 增加上边距，防止 Y 轴标题被裁剪
          left: 50,
          right: 50, // 增加右边距
          containLabel: true,
        },
        
        yAxis: {
          offset: 20,
          name: '人数',
          // interval: 1, // 设置Y轴最小值为0
          nameTextStyle: {
            fontSize: 14, // 设置标题的字体大小
            color: '#333', // 设置标题颜色
          },
          minInterval: 1,
        },
        xAxis: {
          boundaryGap: false,
          axisLabel: this.getAxisLabel(this.form.time),
        },
      })
    },
    //设置图表
    // setChartOption() {
    //   let xAxis = {
    //     boundaryGap: false,
    //     axisLabel: this.getAxisLabel(this.form.time),
    //   }
    //   console.log(xAxis, 'xAxis')

    //   this.$chart.setOption(this.xData, this.chartData, xAxis)
    // },
    // 设置label
    getAxisLabel(times) {
      console.log(times)
      let axisLabel = {
        // rotate: 45,
        showMaxLabel: true,
      }
      // 转换为日期对象
      const start = new Date(times[0] * 1000)
      const end = new Date(times[1] * 1000)

      // 获取时间差（毫秒）
      const timeDifference = Math.abs(end - start)

      // 定义不同天数对应的毫秒数
      const oneDay = 24 * 60 * 60 * 1000 // 1天
      const sevenDays = 7 * oneDay // 7天
      const thirtyDays = 30 * oneDay // 30天
      const threeHundredSixtyFiveDays = 365 * oneDay // 365天

      // 判断时间差是否在指定天数范围内
      if (timeDifference <= oneDay) {
        // return '相差1天内'
        axisLabel.interval = 0
        // 不设置
      } else if (timeDifference <= sevenDays) {
        // return '相差7天内'
        // 2天显示一条数据
        axisLabel.interval = 2
      } else if (timeDifference <= thirtyDays) {
        // return '相差30天内'
        // 5天显示一条数据
        axisLabel.interval = 5
      } else if (timeDifference <= threeHundredSixtyFiveDays) {
        // return '相差365天内'
        // 16天显示一条数据
        axisLabel.interval = 20
      } else {
        // return '相差超过365天'
        // axisLabel.interval = 16
        // 不设置
      }

      return axisLabel
    },

    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose()
      }
    },
  },
  created() {
    this.loading = true
    this.$nextTick(() => {
      //获取时间默认值
      this.$refs['MyDatePicker'].updateTime()
      //发起请求
      this.getData()
    })
  },
  beforeDestroy() {
    this.destroyChart()
  },
  components: {
    BDCard,
    MyDatePicker,
    AssociationSelect,
  },
}
</script>

<style lang="scss" scoped>
.head-box {
  display: flex;
  align-items: center;
  justify-content: center;
  .select-box {
    margin-right: 12px;
  }
}
.member-growth {
  height: 100%;
  display: flex;
  flex-direction: column;
  .head {
    flex-shrink: 0;
    margin-bottom: 10px;
  }
  .content {
    height: 500px;
    min-width: 0;
    flex-grow: 1;
  }
}
</style>
