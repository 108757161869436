import api from '@/base/utils/request'
import fetchFile from '@/base/utils/download'

export const getOverview = (data) => {
  return api({ url: '/admin/study/studyStats/overview', method: 'post', data })
}
export const getStudyCondition = (data) => {
  return api({ url: '/admin/study/studyStats/studyCondition', method: 'post', data })
}

export const getUserGrowth = (data) => {
  return api({ url: '/admin/study/studyStats/userGrowth', method: 'post', data })
}
