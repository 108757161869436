<!--
 * @Author: mulingyuer
 * @Date: 2021-09-16 15:56:09
 * @LastEditTime: 2021-10-20 14:44:10
 * @LastEditors: mulingyuer
 * @Description: 卡片框架
 * @FilePath: \saas-admin-vue\src\modules\gov\layout\big-data\BDCard.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="bd-card">
    <div v-if="!hideHead" class="bd-card-head" :class="{'show-border':!hideHeadBorder}">
      <div class="left">
        <!-- 左侧插槽 -->
        <slot name="headLeft">
          <h2 class="title">{{title}}</h2>
        </slot>
      </div>
      <div class="right">
        <!-- 右侧插槽 -->
        <slot name="headRight">

        </slot>
      </div>
    </div>
    <div class="bd-content" v-loading="loading">
      <!-- 内容插槽 -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //隐藏head
    hideHead: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "标题",
    },
    //隐藏顶部border
    hideHeadBorder: {
      type: Boolean,
      default: false,
    },
    //loading
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bd-card {
  height: 100%;
  border-radius: 2px;
  background-color: $bckgroundW;
  overflow: hidden;
  // resize: both;
  display: flex;
  flex-direction: column;

  & + .bd-card {
    margin-top: $space;
  }

  > .bd-card-head {
    flex-shrink: 0;
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;

    // &.show-border::before {
    //   content: "";
    //   position: absolute;
    //   left: 20px;
    //   right: 20px;
    //   bottom: 0;
    //   border-bottom: 1px solid #e9e9e9;
    // }

    > .left {
      flex-grow: 1;
      min-width: 0;

      .title {
        font-size: 16px;
        height: 22px;
        line-height: 22px;
        font-weight: bold;
        color: #000;
        @include nowrap();
      }
    }

    > .right {
      margin-left: 20px;
    }
  }

  > .bd-content {
    padding: 0 20px 20px;
    flex-grow: 1;
  }
}
</style>