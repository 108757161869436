
//颜色配置
export const colors = [
  "#3ba1ff",
  "#4ecb74",
  "#fbd437",
  "#f04864",
  "#975fe5",
  "#36cbcb",
]
// export const colors = [
//   "#5470C7",
//   "#93CC76",
//   "#FBC858",
//   "#ED6666",
//   "#73C1DF",
// ]